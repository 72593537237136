
<template>
  <div id="renew">
    <!-- <van-notice-bar :scrollable="false" background="#eee" color="#000">
    </van-notice-bar> -->
    <van-nav-bar
      title="我的在借"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
      <van-cell v-for="item in list" :key="item.id">
        <div class="imgbox">
          <img src="../static/image/book2.png" alt="" />
        </div>
        <div class="infobox">
          <p>图书名：{{ item.title }}</p>
          <p>索书号：{{ item.callnum }}</p>
          <p>条码号：{{ item.barcode }}</p>
          <p>应还日期：{{ item.returndate }}</p>
          <p>
            <van-button size="small" type="info" @click="renewing(item)"
              >续借</van-button
            >&nbsp;&nbsp;&nbsp;
            <!-- <van-button size="small" type="info" @click="subtenancy(item)">转借</van-button> -->
          </p>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import { renewData } from "@/api/goods.js";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.getrenewData();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 2; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 2) {
          this.finished = true;
        }
      }, 500);
    },
    //获取借阅记录
    getrenewData() {
      renewData().then((res) => {
        this.finished = true;
        if (res.data.code == 200) {
          this.list = res.data.dataList;
        } else {
          this.list = [];
        }
      });
    },

    //跳转续借
    renewing(bookinfo) {
      this.$router.push({ name: "Renewing", params: bookinfo });
    },
    //跳转转借
    subtenancy(bookinfo) {
      this.$router.push({ name: "Subtenancy", params: bookinfo });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push({
        name: "Index",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#renew {
  width: 100%;
  height: 100%;
  .van-list {
    .van-cell {
      .imgbox {
        float: left;
        width: 40%;
        img {
          width: 92px;
          height: 92px;
        }
      }
      .infobox {
        float: left;
        width: 60%;
        p {
          line-height: 16px;
        }
      }
    }
  }
}
</style>